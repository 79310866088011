const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_QUESTION_DATA':
    return {
      ...initialState
    }
    case 'SET_QUESTION_DATA':
    return {
      ...state,
      questions: action.questions,
      currentIndex: 0
    }
    case 'ANSWER_QUESTION':
    const newQuestions = state.questions.map((question, index) => {
      if (index === action.questionIndex) {
        const newAnswers = question.answers.map((answer, index) => {
          if (index === action.answerIndex) {
            return {
              ...answer,
              isSelected: true
            }
          }
          return {
            ...answer,
            isSelected: false
          };
        });
        return {
          ...question,
          answers: newAnswers
        }
      }
      return question;
    });
    return {
      ...state,
      questions: newQuestions
    }
    case 'NEXT_QUESTION':
    var nextIndex = state.currentIndex + 1;
    if (nextIndex > state.questions.length) {
      nextIndex = state.currentIndex;
    }
    return {
      ...state,
      currentIndex: nextIndex
    }
    case 'PREVIOUS_QUESTION':
    var prevIndex = state.currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = 0;
    }
    return {
      ...state,
      currentIndex: prevIndex
    }
    default:
    return state
  }
}
