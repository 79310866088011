import React from 'react';
import userSelection from '../../containers/userSelection';
import styles from './styles.module.css';

const LanguageSwitcher = (props) => {
  const getStyle = (language) => {
    if (props.userSelection.language === language) {
      return styles.buttonActive;
    }
    return styles.button;
  }

  return (
    <div>
      <button className={getStyle('en')} onClick={() => props.setLanguage('en')}>
        English
      </button>
      <button className={getStyle('es')} onClick={() => props.setLanguage('es')}>
        Español
      </button>
    </div>
  );
}
export default userSelection(LanguageSwitcher);
