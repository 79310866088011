import React from 'react';
import questionData from '../../containers/questionData';
import userSelection from '../../containers/userSelection';
import styles from './styles.module.css';
import previousArrow from '../../assets/control-previous.svg';
import nextArrow from '../../assets/control-next.svg';

const QuestionPage = (props) => {
  const thisQuestion = () => {
    const questions = props.questionData.questions;
    const currentIndex = props.questionData.currentIndex;
    return questions[currentIndex];
  }

  const language = () => {
    if (props.userSelection.language === 'es') {
      return 'es';
    }
    return 'en';
  }

  const renderQuestionCount = () => {
    const totalCount = props.questionData.questions.length;
    const thisCount = props.questionData.currentIndex + 1;
    return (
      <div className={styles.questionCountText}>
        {text[language()].question} {thisCount} {text[language()].of} {totalCount}
      </div>
    );
  }

  const renderQuestion = () => {
    const text = thisQuestion().question[language()];
    return (
      <div className={styles.questionText}>
        {text}
      </div>
    );
  }

  const getStyle = (answerIndex) => {
    if (thisQuestion().answers[answerIndex].isSelected) {
      return styles.buttonSelected;
    }
    return styles.buttonUnselected;
  }

  const getCheckbox = (answerIndex) => {
    const isSelected = thisQuestion().answers[answerIndex].isSelected;
    return <div className={`${styles.checkbox} ${isSelected ? styles.selected : null}`} />;
  }

  const renderAnswers = () => {
    return thisQuestion().answers.map((answer) => answer[language()]).map((answer, i) => (
      <button key={JSON.stringify(answer)} onClick={() => submitAnswer(i)} className={getStyle(i)}>
        {getCheckbox(i)} {answer}
      </button>
    ))
  }

  const submitAnswer = (index) => {
    props.answerQuestion(props.questionData.currentIndex, index);
    if (props.questionData.currentIndex <= props.questionData.questions.length - 1) {
      console.log(props.questionData.currentIndex, props.questionData.questions.length - 1);
      setTimeout(() => {
        props.nextQuestion();
      }, 500);
    }
  }

  const shouldDisplayPrevious = props.questionData.currentIndex > 0;

  const shouldDisplayNext = thisQuestion().answers.some(answer => answer.isSelected);

  const previousQuestion = () => {
    if (shouldDisplayPrevious) {
      props.previousQuestion();
    }
  }

  const nextQuestion = () => {
    if (shouldDisplayNext) {
      props.nextQuestion();
    }
  }

  const renderDots = () => {
    const dotCount = props.questionData.questions.length + 1;
    const currentDotIndex = props.questionData.currentIndex + 1;
    const beforeDotCount = currentDotIndex - 1;
    const afterDotCount = dotCount - (beforeDotCount + 1);
    var data = [];
    for(var i = 0; i < beforeDotCount; i++) {
        data.push(<div className={styles.beforeDot} key={Math.random()}></div>);
    }
    data.push(<div className={styles.currentDot} key={Math.random()}></div>);
    for(var i2 = 0; i2 < afterDotCount; i2++) {
        data.push(<div className={styles.afterDot} key={Math.random()}></div>);
    }
    return <div className={styles.dots}>{data}</div>;
  }

  const text = {
    en: {
      question: "Question",
      of: "of"
    },
    es: {
      question: "Pregunta",
      of: "de"
    }
  }

  return (
    <div className={styles.frame}>
      <button className={`${styles.arrowButton} ${shouldDisplayPrevious ? undefined : styles.hidden}`} onClick={previousQuestion}>
        <img src={previousArrow} alt={'Previous arrow'} className={styles.previousArrow} />
      </button>
      <div className={styles.content}>
        {renderQuestionCount()}
        {renderQuestion()}
        {renderAnswers()}
        {renderDots()}
      </div>
      <button className={`${styles.arrowButton} ${shouldDisplayNext ? undefined : styles.hidden}`} onClick={nextQuestion}>
        <img src={nextArrow} alt={'Next arrow'} className={styles.nextArrow} />
      </button>
    </div>
  );
}

export default userSelection(questionData(QuestionPage));
