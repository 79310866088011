import React from 'react';
import LanguageSwitcher from '../LanguageSwitcher';
import logo from '../../assets/logo-tag.png';
import logo2 from '../../assets/logo-tag@2x.png';
import logo3 from '../../assets/logo-tag@3x.png';
import logoKHSA from '../../assets/logo-khsa.jpg';
import logos from '../../assets/logos.jpeg';
import styles from './styles.module.css';
import questionData from '../../containers/questionData';
import userSelection from '../../containers/userSelection';

const NavHeader = (props) => {
  const reset = () => {
    props.resetQuestionData();
    props.resetUserSelection();
    props.setAboutPageShowing(false);
  }

  const showAboutPage = () => {
    props.setAboutPageShowing(true);
  }

  const getLanguageSwitcherStyle = () => {
    if (props.questionData.questions === undefined) {
      return styles.inline;
    }
    return styles.hide;
  }

  const getRestartStyle = () => {
    if (props.questionData.questions === undefined) {
      return styles.hide;
    }
    return null;
  }

  return (
    <nav className={styles.navHeader}>
      <div className={styles.wrapper}>
        <div className={styles.logoRow}>
          
          <button className={styles.logoHeadStartButton} onClick={reset}>
            <img src={logos} alt={'Logo'} className={styles.logoTag} />
          </button>
        </div>
        <div className={getLanguageSwitcherStyle()}>
          <button className={styles.button} onClick={showAboutPage}>
            {props.userSelection.language === 'es' ? 'Acerca' : 'About'}
          </button>
          &nbsp; | &nbsp;
          <LanguageSwitcher />
        </div>
        <div className={getRestartStyle()}>
          <button className={styles.button} onClick={reset}>
            {props.userSelection.language === 'es' ? 'Reiniciar' : 'Restart'}
          </button>
        </div>
      </div>
    </nav>
  )
}

export default userSelection(questionData(NavHeader));
