const initialState = {
  language: 'en'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_USER_SELECTION':
    return {
      ...initialState
    }
    case 'ADULT_CHILD_SELECTION':
    return {
      ...state,
      userAge: action.selection
    }
    case 'SET_LANGUAGE':
    return {
      ...state,
      language: action.language
    }
    case 'SET_NAME':
    return {
      ...state,
      name: action.name
    }
    case 'SET_ABOUT_PAGE_SHOWING':
    return {
      ...state,
      isAboutPageShowing: action.isShowing
    }
    default:
    return state
  }
}
