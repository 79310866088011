import React from 'react';
import './App.css';
import IntroPage from './components/IntroPage';
import QuestionPage from './components/QuestionPage';
import ResultPage from './components/ResultPage';
import NavHeader from './components/NavHeader';
import userSelection from './containers/userSelection';
import questionData from './containers/questionData';
import firebase from './firebase';

class App extends React.Component {

  fetchQuestions = (ageSelected) => {
    var age;
    switch (ageSelected) {
      case 'adult':
      age = 'adult';
      break;
      case 'over8':
      age = 'child-over8';
      break;
      case 'under8':
      age = 'child-under8';
      break;
      default:
      return;
    }
    const docRef = firebase.firestore().collection("questions").doc(age);
    docRef.get().then((doc) => {
      if (doc.exists) {
        this.props.setQuestionData(doc.data().questions);
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  shouldRenderIntro = () => this.props.questionData.questions === undefined;

  shouldRenderQuestions = () => !this.shouldRenderIntro() && this.props.questionData.currentIndex < this.props.questionData.questions.length;

  shouldRenderTotal = () => !this.shouldRenderIntro() && !this.shouldRenderQuestions();

  render() {
    return (
      <div className="App">
        <NavHeader />
        <div className="app-content">
          {this.shouldRenderIntro() ? <IntroPage didComplete={this.fetchQuestions} /> : null }
          {this.shouldRenderQuestions() ? <QuestionPage /> : null }
          {this.shouldRenderTotal() ? <ResultPage /> : null }
        </div>
      </div>
    );
  }
}

export default questionData(userSelection(App));
