import React from 'react';
import styles from './styles.module.css';
import meter0 from '../../assets/meter-0.png';
import meter0_2x from '../../assets/meter-0@2x.png';
import meter0_3x from '../../assets/meter-0@3x.png';
import meter1 from '../../assets/meter-1.png';
import meter1_2x from '../../assets/meter-1@2x.png';
import meter1_3x from '../../assets/meter-1@3x.png';
import meter2 from '../../assets/meter-2.png';
import meter2_2x from '../../assets/meter-2@2x.png';
import meter2_3x from '../../assets/meter-2@3x.png';
import meter3 from '../../assets/meter-3.png';
import meter3_2x from '../../assets/meter-3@2x.png';
import meter3_3x from '../../assets/meter-3@3x.png';
import meter4 from '../../assets/meter-4.png';
import meter4_2x from '../../assets/meter-4@2x.png';
import meter4_3x from '../../assets/meter-4@3x.png';
import meter5 from '../../assets/meter-5.png';
import meter5_2x from '../../assets/meter-5@2x.png';
import meter5_3x from '../../assets/meter-5@3x.png';
import meter6 from '../../assets/meter-6.png';
import meter6_2x from '../../assets/meter-6@2x.png';
import meter6_3x from '../../assets/meter-6@3x.png';
import meter7 from '../../assets/meter-7.png';
import meter7_2x from '../../assets/meter-7@2x.png';
import meter7_3x from '../../assets/meter-7@3x.png';
import meter8 from '../../assets/meter-8.png';
import meter8_2x from '../../assets/meter-8@2x.png';
import meter8_3x from '../../assets/meter-8@3x.png';
import meter9 from '../../assets/meter-9.png';
import meter9_2x from '../../assets/meter-9@2x.png';
import meter9_3x from '../../assets/meter-9@3x.png';
import meter10 from '../../assets/meter-10.png';
import meter10_2x from '../../assets/meter-10@2x.png';
import meter10_3x from '../../assets/meter-10@3x.png';

const ResultMeter = (props) => {

  const getImage = () => {
    switch (props.score) {
      case 0:
        return {
          size1: meter0,
          size2: meter0_2x,
          size3: meter0_3x
        };
      case 1:
        return {
          size1: meter1,
          size2: meter1_2x,
          size3: meter1_3x
        };
      case 2:
        return {
          size1: meter2,
          size2: meter2_2x,
          size3: meter2_3x
        };
      case 3:
        return {
          size1: meter3,
          size2: meter3_2x,
          size3: meter3_3x
        };
      case 4:
        return {
          size1: meter4,
          size2: meter4_2x,
          size3: meter4_3x
        };
      case 5:
        return {
          size1: meter5,
          size2: meter5_2x,
          size3: meter5_3x
        };
      case 6:
        return {
          size1: meter6,
          size2: meter6_2x,
          size3: meter6_3x
        };
      case 7:
        return {
          size1: meter7,
          size2: meter7_2x,
          size3: meter7_3x
        };
      case 8:
        return {
          size1: meter8,
          size2: meter8_2x,
          size3: meter8_3x
        };
      case 9:
        return {
          size1: meter9,
          size2: meter9_2x,
          size3: meter9_3x
        };
      case 10:
        return {
          size1: meter10,
          size2: meter10_2x,
          size3: meter10_3x
        };
      default:
        return null;
    }
  }

  return (
    <div className={styles.container}>
      <img src={getImage().size1} alt={'Meter'} srcSet={`${getImage().size2} 2x, ${getImage().size3} 3x`} className={styles.meter} />
    </div>
  )
}
export default ResultMeter;
