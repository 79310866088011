import React from 'react';
import IntroButton from '../IntroButton';
import userSelection from '../../containers/userSelection';
import styles from './styles.module.css';

const IntroPage = (props) => {

  const handleNameChange = (e) => {
    props.setName(e.target.value);
  }

  const language = () => {
    if (props.userSelection.language === 'es') {
      return 'es';
    }
    return 'en';
  }

  const buttonText = {
    adult: {
      en: {
        line1: "I am an",
        line2: "Adult"
      },
      es: {
        line1: "Soy un",
        line2: "Adulto"
      }
    },
    child: {
      en: {
        line1: "I am a",
        line2: "Child"
      },
      es: {
        line1: "Soy un",
        line2: "Niño"
      }
    },
    under8: {
      en: {
        line1: "Under",
        line2: "8"
      },
      es: {
        line1: "Menos de",
        line2: "8"
      }
    },
    over8: {
      en: {
        line1: "Over",
        line2: "8"
      },
      es: {
        line1: "Mas de",
        line2: "8"
      }
    },
    riskAssessment: {
      en: 'Mighty Molar Cavity Quiz',
      es: 'Mighty Molar Cavity Quiz'
    },
    howOldIsTheChild: {
      en: 'How old is the child?',
      es: '¿Qué edad tiene el niño?'
    },
    enterName: {
      en: 'Enter name',
      es: 'Ingrese su nombre'
    },
    description: {
      en: [
        "Risky business: How safe are your teeth?"
        // "Are you ready for fun? Answering the questions on Mighty Molar's Cavity Quiz will tell you just how healthy your oral habits are and what your chances are of having problems with your teeth in the future.",
        // "There are no right or wrong answers, just opportunities to learn more about keeping your teeth healthy.",
        // "To get the best information, answer using what you are actually doing, not what you know you should do."
      ],
      es: [
        "Negocio riesgoso: ¿Qué tan seguros son tus dientes?"
        // "¿Estás listo para divertirte? Respondiendo a las preguntas en la Prueba Sobre Una Caries le dice como saludable son sus hábitos dentales y las posibilidades de tener problemas con sus dientes en el futuro.",
        // "No hay respuestas correctos o incorrectas, sólo oportunidades para aprender más sobre manteniendo sus dientes saludables.",
        // "Para obtener la mejor información, responde con lo que realmente está haciendo, no lo que usted sabe debe hacer"
      ]
    }
  }

  const getText = (button) => {
    if (buttonText[button] && buttonText[button][language()]) {
      return buttonText[button][language()];
    }
    return {};
  }

  const renderUnknownAge = () => (
    <div className={styles.buttonContainer}>
      <IntroButton before={getText('adult').line1} primary={getText('adult').line2} action={() => props.adultChildSelection('adult')} />
      <IntroButton before={getText('child').line1} primary={getText('child').line2} action={() => props.adultChildSelection('child')} />
    </div>
  );

  const renderChildAge = () => (
    <div className={styles.buttonContainer}>
      <IntroButton after={getText('under8').line1} primary={getText('under8').line2} action={() => props.adultChildSelection('under8')} />
      <IntroButton before={getText('over8').line1} primary={getText('over8').line2} action={() => props.adultChildSelection('over8')} />
    </div>
  );

  const styledTitle = () => {
    const title = getText('riskAssessment');
    const sections = title.split(" ");
    return {
      highlighted: sections.slice(2).join(" "),
      theRest: [ sections[0], sections[1] ].join(" ")
    };
  }

  const renderFirstTitle = () => {
    return <div className={styles.headerText}>
      {styledTitle().theRest} <span className={styles.highlighted}>{styledTitle().highlighted}</span>
    </div>;
  }

  const renderChildTitle = () => {
    return <div className={styles.headerText}>
      {getText('howOldIsTheChild')}
    </div>;
  }

  const renderEnterNameTitle = () => {
    return <div className={styles.headerText}>
      {getText('enterName')}
    </div>;
  }

  const renderEnterName = () => {
    return <div>
      <input type="text" className={styles.nameInput} onChange={handleNameChange} />
      <button onClick={() => {props.didComplete(props.userSelection.userAge)}} className={styles.saveButton}>
        Save
      </button>
      <br />
      <button onClick={() => {
        props.setName("");
        props.didComplete(props.userSelection.userAge);
      }} className={styles.skipButton}>
        Skip
      </button>
    </div>;
  }

  const renderDescription = () => {
    return getText('description').map(line => (
      <div className={styles.descriptionCopy} key={line}>
        {line}
      </div>
    ))
  }

  const getContent = () => {
    if (props.userSelection.userAge === undefined) {
      return (
        <div className={styles.content}>
          {renderFirstTitle()}
          {renderDescription()}
          {renderUnknownAge()}
        </div>
      )
    }
    if (props.userSelection.userAge === 'child') {
      return (
        <div className={styles.content}>
          {renderChildTitle()}
          {renderChildAge()}
        </div>
      )
    }
    return (
      <div className={styles.content}>
        {renderEnterNameTitle()}
        {renderEnterName()}
      </div>
    )
  }

  const renderAboutPage = () => {
    if (language() === 'es') {
      return (
        <div className={styles.content}>
          <div className={styles.aboutBackground}>
            <div className={styles.aboutText}>
              ¿Estás listo para divertirte? Respondiendo a las preguntas en la Prueba Sobre Una Caries le dice como saludable son sus hábitos dentales y las posibilidades de tener problemas con sus dientes en el futuro.
            </div>
            <div className={styles.aboutText}>
              No hay respuestas correctos o incorrectas, sólo oportunidades para aprender más sobre manteniendo sus dientes saludables.
            </div>
            <div className={styles.aboutText}>
              Para obtener la mejor información, responde con lo que realmente está haciendo, no lo que usted sabe debe hacer
            </div>
            <div className={styles.aboutText}>
              Developed by Kansas Head Start Association in 2014 | <a href="http://www.ksheadstart.org">www.ksheadstart.org</a>
            </div>
            <div className={styles.aboutText}>
              Updated by Oral Health Kansas in 2019 | <a href="http://www.oralhealthkansas.org">www.oralhealthkansas.org</a>
            </div>
            <button onClick={() => {
              props.setAboutPageShowing(false);
            }} className={styles.saveButton}>
              Empezar
            </button>
          </div>
        </div>
      );
    }
    if (language() === 'en') {
      return (
        <div className={styles.content}>
          <div className={styles.aboutBackground}>
            <div className={styles.aboutText}>
              Are you ready for fun? Answering the questions on Mighty Molar's Cavity Quiz will tell you just how healthy your oral habits are and what your chances are of having problems with your teeth in the future.
            </div>
            <div className={styles.aboutText}>
              There are no right or wrong answers, just opportunities to learn more about keeping your teeth healthy.
            </div>
            <div className={styles.aboutText}>
              To get the best information, answer using what you are actually doing, not what you know you should do.
            </div>
            <div className={styles.aboutText}>
              Developed by Kansas Head Start Association in 2014 | <a href="http://www.ksheadstart.org">www.ksheadstart.org</a>
            </div>
            <div className={styles.aboutText}>
              Updated by Oral Health Kansas in 2019 | <a href="http://www.oralhealthkansas.org">www.oralhealthkansas.org</a>
            </div>
            <button onClick={() => {
              props.setAboutPageShowing(false);
            }} className={styles.saveButton}>
              Begin
            </button>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div className={styles.frame}>
      {props.userSelection.isAboutPageShowing ? renderAboutPage() : getContent()}
    </div>
  );
}

export default userSelection(IntroPage);
