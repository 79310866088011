const resetQuestionData = () => dispatch => {
  dispatch({
    type: 'RESET_QUESTION_DATA'
  })
}

const setQuestionData = (questions) => dispatch => {
  dispatch({
    type: 'SET_QUESTION_DATA',
    questions
  })
}

const answerQuestion = (questionIndex, answerIndex) => dispatch => {
  dispatch({
    type: 'ANSWER_QUESTION',
    questionIndex,
    answerIndex
  })
}

const nextQuestion = () => dispatch => {
  dispatch({
    type: 'NEXT_QUESTION'
  })
}

const previousQuestion = () => dispatch => {
  dispatch({
    type: 'PREVIOUS_QUESTION'
  })
}

export default {
  resetQuestionData,
  setQuestionData,
  answerQuestion,
  nextQuestion,
  previousQuestion
};
