const resetUserSelection = () => dispatch => {
  dispatch({
    type: 'RESET_USER_SELECTION'
  })
}

const adultChildSelection = (selection) => dispatch => {
  dispatch({
    type: 'ADULT_CHILD_SELECTION',
    selection: selection
  })
}

const setLanguage = (language) => dispatch => {
  dispatch({
    type: 'SET_LANGUAGE',
    language: language
  })
}

const setName = (name) => dispatch => {
  dispatch({
    type: 'SET_NAME',
    name: name
  })
}

const setAboutPageShowing = (isShowing) => dispatch => {
  dispatch({
    type: 'SET_ABOUT_PAGE_SHOWING',
    isShowing: isShowing
  })
}

export default {
  resetUserSelection,
  adultChildSelection,
  setLanguage,
  setName,
  setAboutPageShowing
};
