import baseContainer from './baseContainer';
import userSelection from '../redux/actions/userSelection';

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  resetUserSelection: () => dispatch(userSelection.resetUserSelection()),
  adultChildSelection: (selection) => dispatch(userSelection.adultChildSelection(selection)),
  setLanguage: (language) => dispatch(userSelection.setLanguage(language)),
  setName: (name) => dispatch(userSelection.setName(name)),
  setAboutPageShowing: (isShowing) => dispatch(userSelection.setAboutPageShowing(isShowing))
});

export default baseContainer(mapStateToProps, mapDispatchToProps);
