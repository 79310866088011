import React from 'react';
import styles from './styles.module.css';
import a1 from '../../assets/a.png';
import a2 from '../../assets/a@2x.png';
import a3 from '../../assets/a@3x.png';
import b1 from '../../assets/b.png';
import b2 from '../../assets/b@2x.png';
import b3 from '../../assets/b@3x.png';
import c1 from '../../assets/c.png';
import c2 from '../../assets/c@2x.png';
import c3 from '../../assets/c@3x.png';
import d1 from '../../assets/d.png';
import d2 from '../../assets/d@2x.png';
import d3 from '../../assets/d@3x.png';
import e1 from '../../assets/e.png';
import e2 from '../../assets/e@2x.png';
import e3 from '../../assets/e@3x.png';
import f1 from '../../assets/f.png';
import f2 from '../../assets/f@2x.png';
import f3 from '../../assets/f@3x.png';

const ResultIllustration = (props) => {

  switch (props.score) {
    case 0:
      return <img src={a1} alt={'Tooth illustration'} srcSet={`${a2} 2x, ${a3} 3x`} className={styles.illustration} />;
    case 1:
      return <img src={a1} alt={'Tooth illustration'} srcSet={`${a2} 2x, ${a3} 3x`} className={styles.illustration} />;
    case 2:
      return <img src={b1} alt={'Tooth illustration'} srcSet={`${b2} 2x, ${b3} 3x`} className={styles.illustration} />;
    case 3:
      return <img src={b1} alt={'Tooth illustration'} srcSet={`${b2} 2x, ${b3} 3x`} className={styles.illustration} />;
    case 4:
      return <img src={c1} alt={'Tooth illustration'} srcSet={`${c2} 2x, ${c3} 3x`} className={styles.illustration} />;
    case 5:
      return <img src={c1} alt={'Tooth illustration'} srcSet={`${c2} 2x, ${c3} 3x`} className={styles.illustration} />;
    case 6:
      return <img src={d1} alt={'Tooth illustration'} srcSet={`${d2} 2x, ${d3} 3x`} className={styles.illustration} />;
    case 7:
      return <img src={e1} alt={'Tooth illustration'} srcSet={`${e2} 2x, ${e3} 3x`} className={styles.illustration} />;
    case 8:
      return <img src={e1} alt={'Tooth illustration'} srcSet={`${e2} 2x, ${e3} 3x`} className={styles.illustration} />;
    case 9:
      return <img src={f1} alt={'Tooth illustration'} srcSet={`${f2} 2x, ${f3} 3x`} className={styles.illustration} />;
    case 10:
      return <img src={f1} alt={'Tooth illustration'} srcSet={`${f2} 2x, ${f3} 3x`} className={styles.illustration} />;
    default:
      return null;
  }

}
export default ResultIllustration;
