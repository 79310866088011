import React from 'react';
import styles from './styles.module.css';

const IntroButton = (props) => (
  <button onClick={props.action} className={styles.button}>
    <div className={styles.beforeText}>
    {props.before || String.fromCharCode(8205)}
    </div>
    <div className={styles.primaryText}>
    {props.primary || " "}
    </div>
    <div className={styles.afterText}>
    {props.after || String.fromCharCode(8205)}
    </div>
  </button>
);

export default IntroButton;
