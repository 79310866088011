import baseContainer from './baseContainer';
import questionData from '../redux/actions/questionData';

const mapStateToProps = state => ({
 ...state
});

const mapDispatchToProps = dispatch => ({
  resetQuestionData: (questions) => dispatch(questionData.resetQuestionData()),
  setQuestionData: (questions) => dispatch(questionData.setQuestionData(questions)),
  answerQuestion: (questionIndex, answerIndex) => dispatch(questionData.answerQuestion(questionIndex, answerIndex)),
  nextQuestion: () => dispatch(questionData.nextQuestion()),
  previousQuestion: () => dispatch(questionData.previousQuestion())
});

export default baseContainer(mapStateToProps, mapDispatchToProps);
