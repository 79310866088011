import React from 'react';
import ResultIllustration from '../ResultIllustration';
import ResultMeter from '../ResultMeter';
import styles from './styles.module.css';
import questionData from '../../containers/questionData';
import userSelection from '../../containers/userSelection';

const ResultPage = (props) => {

  const resultsCopy = () => {
    if (totalRatio() <= 1) {
      return {
        en: "Great job! You have good habits that keep your teeth healthy. Keep it up!",
        es: "¡Buen trabajo! Tienes buenos hábitos que mantienen tus dientes sanos. ¡Siga haciéndolo!"
      }
    }
    if (totalRatio() <= 4) {
      return {
        en: "Nice work! You do many things to keep your teeth healthy. The good news is that there are even more actions you can take.  Ask your dentist or dental hygienist for more information.",
        es: "¡Buen trabajo! Haces muchas cosas para mantener tus dientes sanos. La buena noticia es que hay más acciones que puedes tomar. Pregúntale a tu dentista o higienista dental para más información."
      }
    }
    if (totalRatio() <= 7) {
      return {
        en: "Oops! You have several habits that put your teeth at risk for disease. Ask your dentist or dental hygienist for more information.",
        es: "¡Oops! Tienes varios hábitos que ponen tus dientes en riesgo para enfermedad. Pregúntale a tu dentista o higienista dental para más información."
      }
    }
    return {
      en: "Yikes! It looks like you have a pretty high risk for dental disease. The good news is that you can choose new daily habits right now to lower your risk. Ask your dentist or dental hygienist for more information.",
      es: "¡Yikes! Se parece que tienes un riesgo muy alto para enfermedad dental. La buena noticia es que puedes elegir nuevos hábitos diarios ahora para reducir su riesgo. Pregúntale a tu dentista o higienista dental para más información."
    }
  }

  const totalScore = () => {
    return props.questionData.questions.reduce((total, question) => {
      return total + question.answers.find(answer => answer.isSelected).value;
    }, 0);
  }

  const totalMaximum = () => {
    return props.questionData.questions.reduce((total, question) => {
      return total + question.answers.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      }).value;
    }, 0);
  }

  const totalRatio = () => {
    const ratio = totalScore() / totalMaximum();
    const scaleOf10 = ratio * 10;
    const rounded = Math.round(scaleOf10);
    return rounded;
  }

  const language = () => {
    if (props.userSelection.language === 'es') {
      return 'es';
    }
    return 'en';
  }

  const text = {
    results: {
      en: 'Mighty Molar Cavity Quiz Results',
      es: 'Mighty Molar Cavity Quiz Resultados'
    },
    printResults: {
      en: 'Print results',
      es: 'Imprimir resultados'
    },
    emailResults: {
      en: 'Email results',
      es: 'Resultados de correo electrónico'
    },
    email: {
      en: 'Have any questions? Please email.',
      es: '¿Tiene alguna pregunta? Por favor envíe un correo electrónico.'
    }
  }

  const renderDots = () => {
    const beforeDotCount = props.questionData.questions.length;
    var data = [];
    for(var i = 0; i < beforeDotCount; i++) {
        data.push(<div className={styles.beforeDot} key={Math.random()}></div>);
    }
    data.push(<div className={styles.currentDot} key={Math.random()}></div>);
    return <div className={styles.dots}>{data}</div>;
  }

  const getDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
  }

  const emailResults = () => {
    const body = `${text.results[language()]}\n${props.userSelection.name} - ${getDate()}\n\n${totalRatio()}/10\n\n${resultsCopy()[language()]}\n\nhttp://www.cavityquiz.org`;
    window.location.href = `mailto:?subject=${encodeURIComponent(text.results[language()])}&body=${encodeURIComponent(body)}`;
  }

  return (
    <div className={styles.frame}>
      <div>
        <div className={styles.resultsTitle}>
          {text.results[language()]}
          <br />
          {props.userSelection.name} - {getDate()}
        </div>
        <ResultIllustration score={totalRatio()} />
        <ResultMeter score={totalRatio()} />
        <div className={styles.resultsCopy}>
          {resultsCopy()[language()]}
        </div>
        <button onClick={window.print} className={styles.printButton}>
          {text.printResults[language()]}
        </button>
        <button onClick={emailResults} className={styles.printButton}>
          {text.emailResults[language()]}
        </button>
        {renderDots()}
      </div>
    </div>
  );
}

export default userSelection(questionData(ResultPage));
