import firebase from 'firebase';
const config = {
  apiKey: "AIzaSyAvJQO7wbVvK1hxxsvoqlyUTCN6efcMO1M",
  authDomain: "mighty-molar.firebaseapp.com",
  databaseURL: "https://mighty-molar.firebaseio.com",
  projectId: "mighty-molar",
  storageBucket: "mighty-molar.appspot.com",
  messagingSenderId: "211266525207"
};
firebase.initializeApp(config);
export default firebase;
